import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import OrderDetailModal from './OrderDetailModal';
import ListContentHeader from '../layout/ListContentHeader';
import Loader from '../utils/Loader';
import SmallLoader from '../utils/SmallLoader';
import { getOrders } from '../../api';

const OrderList = ({ accessToken }) => {
  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [filterText, setFilterText] = useState('');
  const [filterParams, setFilterParams] = useState({});
  const [totalResults, setTotalResults] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchingMore, setIsSearchingMore] = useState(false);
  const [orderDetailModalOpen, setOrderDetailModalOpen] = useState(false);
  const [orderDetailModalOrderNumber, setOrderDetailModalOrderNumber] = useState(null);
  const [orderDetailModalData, setOrderDetailModalData] = useState([]);

  const navigate = useNavigate();

  const handleEditOrder = (event, orderUuid) => {
    navigate(`/orders/edit/${orderUuid}`);
  };

  const handleSubmitFilter = async () => {
    const params = {};

    if (filterText !== '') {
      params.searchTerm = filterText;
    }

    setFilterParams(params);
  };

  const handleResetFilter = () => {
    setFilterText('');
    setFilterParams({});
  };

  const handleClearFilterInput = () => {
    setFilterText('');
  };

  const handleShowMore = () => {
    setIsSearchingMore(true);
    setPage(page + 1);
  };

  const handleOpenOrderDetailModal = (event, orderNumber, data) => {
    setOrderDetailModalOrderNumber(orderNumber);
    setOrderDetailModalData(data);
    setOrderDetailModalOpen(true);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const res = await getOrders(accessToken, 20, page, filterParams);

        if (res) {
          setOrders([...orders, ...res.data.data]);
          setTotalResults(res.data.meta.total);
        } else {
          console.error('Error fetching orders');

          setError('Nepodařilo se stáhnout seznam objednávek');
        }
      } catch (err) {
        console.error(err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
        setIsSearchingMore(false);
      }
    };

    fetchOrders();
  }, [accessToken, filterParams, page]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <ListContentHeader icon={<ChecklistIcon />} title={'Objednávky'} />
      {error && <Alert severity="error">{error}</Alert>}
      <Box sx={{ margin: 2 }}>
        <Grid container>
          <Grid item>
            <TextField
              id="filter-term"
              placeholder="Číslo objednávky"
              variant="outlined"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: filterText ? 'visible' : 'hidden' }}
                    onClick={handleClearFilterInput}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitFilter}
              sx={{ margin: 1 }}
            >
              Filtrovat
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleResetFilter}
              sx={{ margin: 1 }}
            >
              Vymazat filtr
            </Button>
          </Grid>
        </Grid>
        <Typography
          variant="overline"
          display="block"
          sx={{ marginLeft: 1, marginTop: 1 }}
          gutterBottom
        >
          zobrazeno {orders.length} z celkového počtu {totalResults} nalezených objednávek
        </Typography>
      </Box>
      {orders.length > 0 ? (
        <div>
          <TableContainer component={Paper} sx={{ marginTop: 5 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Číslo objednávky</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Cena</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Počet položek</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Stav</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Vytvořena</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow
                    key={order.uuid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>#{order.order_number}</TableCell>
                    <TableCell>{order.price_total} Kč</TableCell>
                    <TableCell>{order.order_items.length}</TableCell>
                    <TableCell>{order.status}</TableCell>
                    <TableCell>
                      {dayjs(new Date(order.created_at)).format('DD.MM.YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Zobrazit detail">
                        <FindInPageOutlinedIcon
                          fontSize="medium"
                          sx={{ cursor: 'pointer' }}
                          onClick={(e) => handleOpenOrderDetailModal(e, order.order_number, order)}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Upravit">
                        <EditIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={(e) => handleEditOrder(e, order.uuid)}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
                {isSearchingMore ? (
                  <SmallLoader />
                ) : (
                  <>
                    {totalResults > orders.length && (
                      <TableRow>
                        <TableCell
                          colSpan={11}
                          sx={{ alignItems: 'center', alignContent: 'center' }}
                        >
                          <Button
                            type="button"
                            variant="contained"
                            className="btn btn-link btn-primary"
                            onClick={(e) => handleShowMore(e)}
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            Načíst další
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <OrderDetailModal
            open={orderDetailModalOpen}
            setOpen={setOrderDetailModalOpen}
            orderNumber={orderDetailModalOrderNumber}
            data={orderDetailModalData}
          />
        </div>
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Zatím nemáte žádné objednávky
            </Typography>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
};

export default OrderList;
