const ENV_LOCAL = 'local';
const ENV_TEST = 'test';
const ENV_PROD = 'production';

function getEnvironment(hostname) {
  switch (hostname) {
    case 'localhost':
      return ENV_LOCAL;
    case 'test.sempro.urban-technologies.com':
      return ENV_TEST;
    default:
      return ENV_PROD;
  }
}

function getApiUrl(env) {
  if (env === ENV_LOCAL) {
    return 'http://localhost:8321';
  }

  if (env === ENV_TEST) {
    return 'https://test.sempro.urban-technologies.com';
  }

  return 'https://sempro.urban-technologies.com';
}

export const ENVIRONMENT = getEnvironment(window.location.hostname);
export const API_BASE_URL = getApiUrl(ENVIRONMENT);

// export const API_BASE_URL = process.env.REACT_APP_ENV || 'https://sempro.urban-technologies.com';
// export const API_BASE_URL = process.env.NODE_ENV === 'development'
//     ? 'http://localhost:8321'
//     : 'https://sempro.urban-technologies.com';
