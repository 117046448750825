import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import MiniDrawerSideMenu, { DrawerHeader } from './MiniDrawerSideMenu';
import ResponsiveAppBar from './ResponsiveAppBar';
import Router from '../../routes';
import { useAuth } from '../../hooks/useAuth';
import bgImageBarley from '../../assets/images/barley_1920.jpg';
import bgImageWheat from '../../assets/images/wheat_1920.jpg';

const drawerWidth = 240;

export default function Layout() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const randomBoolean = Math.random() < 0.5;
  const bgImage = randomBoolean ? bgImageBarley : bgImageWheat;

  const mainStyle = {
    flexGrow: 1,
    p: 3,
    backgroundImage: !user ? `url('${bgImage}')` : null,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ResponsiveAppBar drawerWidth={drawerWidth} handleDrawerOpen={handleDrawerOpen} open={open} />
      {user && (
        <MiniDrawerSideMenu
          drawerWidth={drawerWidth}
          handleDrawerClose={handleDrawerClose}
          open={open}
        />
      )}
      <Box component="main" sx={mainStyle}>
        {user && <DrawerHeader />}
        <Container>
          <Router />
        </Container>
      </Box>
    </Box>
  );
}
