import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://www.mdsoft.cz/" target="_blank">
        {'MD Soft'}
      </Link>
      {' &'}
      <Link color="inherit" href="https://www.urbandigital.cz/" target="_blank">
        {'Urban Digital'}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
