import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Copyright from '../Copyright';
import Loader from '../utils/Loader';
import { login, getLoggedUser } from '../../api';
import { useAuth } from '../../hooks/useAuth';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const defaultTheme = createTheme();

const LoginForm = () => {
  const { login: onLogin } = useAuth();

  const { setItem, getItem } = useLocalStorage();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadRememberValues = () => {
      const rememberDefaults = {
        username: '',
        password: '',
        rememberMe: false,
      };

      const remember = JSON.parse(getItem('remember')) || rememberDefaults;

      setUsername(remember.username);
      setPassword(remember.password);
      setRememberMe(remember.rememberMe);
    };

    loadRememberValues();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      const loginRes = await login(username, password);
      const loginData = loginRes.data.data;

      const accessToken = loginData.access_token;

      const userRes = await getLoggedUser(accessToken);
      const user = userRes.data;

      const userData = {
        ...loginData,
        name: user.name,
        isSuperAdmin: user.roles.includes('SUPER_ADMIN'),
        isAdmin: user.roles.includes('ADMIN') || user.roles.includes('SUPER_ADMIN'),
      };

      onLogin(userData);

      handleRememberMe();

      navigate('/');
    } catch (err) {
      console.error('Login error', err);

      if (err.response) {
        if (err.response.status === 401) {
          setError('Špatné přihlašovací údaje');
        } else {
          setError(err.response.data.message);
        }
      } else {
        setError('Došlo k neznámé chybě. Opakujte akci znovu prosím.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleRememberMe = () => {
    if (rememberMe) {
      setItem(
        'remember',
        JSON.stringify({
          username,
          password,
          rememberMe,
        })
      );
    } else {
      setItem('remember', JSON.stringify(''));
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', pb: 1 }}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            paddingTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Přihlášení
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="username"
              label="Přihlašovací jméno"
              id="username"
              value={username}
              autoComplete="username"
              onChange={handleUsernameChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Heslo"
              type="password"
              id="password"
              value={password}
              autoComplete="current-password"
              onChange={handlePasswordChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={rememberMe}
                  checked={rememberMe}
                  color="primary"
                  onChange={handleRememberMeChange}
                />
              }
              label="Zapamatovat si mě"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Přihlásit
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/password-reset" variant="body2">
                  Zapomenuté heslo?
                </Link>
              </Grid>
              <Grid item xs>
                <Link href="mailto:info@urbandigital.cz" variant="body2" style={{ float: 'right' }}>
                  Kontaktujte nás
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default LoginForm;
