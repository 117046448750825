import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import RemoveTrailingSlash from './RemoveTrailingSlash';
import CategoryForm from '../components/category/CategoryForm';
import CategoryList from '../components/category/CategoryList';
import Dashboard from '../components/Dashboard';
import LoginForm from '../components/auth/LoginForm';
import Logout from '../components/auth/Logout';
import RegistrationForm from '../components/auth/RegistrationForm';
import OrderList from '../components/order/OrderList';
import PasswordReset from '../components/auth/PasswordReset';
import UserAccount from '../components/UserAccount';
import UserForm from '../components/user/UserForm';
import UserList from '../components/user/UserList';
import { useAuth } from '../hooks/useAuth';

function Router() {
  const { user } = useAuth();
  const accessToken = user ? user.access_token : null;
  const isSuperAdmin = user ? user.isSuperAdmin || false : false;
  const isAdmin = user ? user.isAdmin || isSuperAdmin || false : false;

  if (!user) {
    return (
      <React.Fragment>
        <RemoveTrailingSlash />
        <Routes>
          <Route index element={<LoginForm />} />
          <Route path="registration" element={<RegistrationForm />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <RemoveTrailingSlash />
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="categories" exact={true}>
          <Route
            index={true}
            element={
              <ProtectedRoute token={accessToken}>
                <CategoryList accessToken={accessToken} />
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute token={accessToken}>
                <CategoryForm accessToken={accessToken} isCreate={true} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":uuid/edit"
            element={
              <ProtectedRoute token={accessToken}>
                <CategoryForm accessToken={accessToken} isCreate={false} />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="orders" exact={true}>
          <Route
            index={true}
            element={
              <ProtectedRoute token={accessToken}>
                <OrderList accessToken={accessToken} />
              </ProtectedRoute>
            }
          />
        </Route>
        {isSuperAdmin && (
          <Route path="users" exact={true}>
            <Route
              index={true}
              element={
                <ProtectedRoute token={accessToken}>
                  <UserList accessToken={accessToken} />
                </ProtectedRoute>
              }
            />
            <Route
              path="create"
              element={
                <ProtectedRoute token={accessToken}>
                  <UserForm accessToken={accessToken} isCreate={true} isAdmin={isAdmin} />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/edit"
              element={
                <ProtectedRoute token={accessToken}>
                  <UserForm accessToken={accessToken} isCreate={false} isAdmin={isAdmin} />
                </ProtectedRoute>
              }
            />
          </Route>
        )}
        <Route
          path="user/account"
          element={
            <ProtectedRoute token={accessToken}>
              <UserAccount accessToken={accessToken} />
            </ProtectedRoute>
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </React.Fragment>
  );
}

export default Router;
