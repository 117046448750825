import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import GroupIcon from '@mui/icons-material/Group';
import UserPasswordReset from './UserPasswordReset';
import ListContentHeader from '../layout/ListContentHeader';
import AddButton from '../utils/buttons/AddButton';
import ClearFilterButton from '../utils/buttons/ClearFilterButton';
import EditButton from '../utils/buttons/EditButton';
import DeleteButton from '../utils/buttons/DeleteButton';
import ConfirmationDialog from '../utils/dialogs/ConfirmationDialog';
import IsActiveSwitch from '../utils/IsActiveSwitch';
import Loader from '../utils/Loader';
import { getUsers, updateUser, deleteUser } from '../../api';

const UserList = ({ accessToken }) => {
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogValue, setConfirmDialogValue] = useState(null);
  const navigate = useNavigate();

  const handleResetFilter = () => {
    setFilterText('');
  };

  const handleChangeIsUserActive = async (userUuid, isActive, user) => {
    await updateUser(accessToken, userUuid, { ...user, active: isActive });
  };

  const handleCreateUser = (e) => {
    navigate('/users/create');
  };

  const handleEditUser = (e, userUuid) => {
    navigate(`/users/${userUuid}/edit`);
  };

  const handleDeleteUser = (e, userUuid) => {
    setOpenConfirmDialog(true);
    setConfirmDialogValue(userUuid);
  };

  const handleCloseConfirmDialog = async (userUuid) => {
    setOpenConfirmDialog(false);
    setConfirmDialogValue(null);

    if (userUuid) {
      await deleteUser(accessToken, userUuid);

      const updatedUsers = users.filter((user) => user.uuid !== userUuid);

      setUsers(updatedUsers);
    }
  };

  useEffect(() => {
    if (filterText === '') {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        filteredUsers.filter((user) => {
          return (
            user.name.toLowerCase().includes(filterText.toLowerCase()) ||
            user.surname.toLowerCase().includes(filterText.toLowerCase()) ||
            user.email.toLowerCase().includes(filterText.toLowerCase())
          );
        })
      );
    }
  }, [filterText]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await getUsers(accessToken);

        if (res) {
          setUsers(res.data);
          setFilteredUsers(res.data);
        } else {
          console.error('Error fetching users');

          setError('Nepodařilo se stáhnout seznam uživatelů');
        }
      } catch (err) {
        console.error(err);

        if (err.response) {
          setError(err.response.message);
        }

        if (err.message) {
          setError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [accessToken]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <ListContentHeader icon={<GroupIcon />} title={'Uživatelé'} />
      {error && <Alert severity="error">{error}</Alert>}
      {users.length > 0 && (
        <Box sx={{ margin: 2 }}>
          <TextField
            id="outlined-basic"
            placeholder="Jméno, příjmení, e-mail, ..."
            variant="outlined"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <ClearFilterButton
            label="Vymazat filtr"
            onClick={handleResetFilter}
            startIcon={<FilterAltOffIcon />}
            sx={{ margin: 1 }}
          />
          <Typography variant="overline" display="block" sx={{ marginLeft: 1 }} gutterBottom>
            nalezeno {filteredUsers.length} uživatelů
          </Typography>
        </Box>
      )}
      <AddButton label={'Přidat'} variant="contained" color="success" onClick={handleCreateUser} />
      {filteredUsers.length > 0 ? (
        <div>
          <TableContainer component={Paper} sx={{ marginTop: 5 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Jméno</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Příjmení</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>E-mail</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Role</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Aktivní</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Poslední přihlášení</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user) => (
                  <TableRow
                    key={user.uuid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.surname}</TableCell>
                    <TableCell>
                      <Link href={`mailto:${user.email}`}>{user.email}</Link>
                    </TableCell>
                    <TableCell>{user.roles.join(', ')}</TableCell>
                    <TableCell>
                      <IsActiveSwitch
                        itemId={user.uuid}
                        defaultIsActive={user.active}
                        item={user}
                        onClickSwitch={handleChangeIsUserActive}
                      />
                    </TableCell>
                    <TableCell>
                      {user.last_login_at
                        ? dayjs(new Date(user.last_login_at)).format('DD.MM.YYYY HH:mm:ss')
                        : 'Zatím nepřihlášen(a)'}
                    </TableCell>
                    <TableCell>
                      <UserPasswordReset accessToken={accessToken} user={user} />
                    </TableCell>
                    <TableCell>
                      <EditButton
                        label={'Upravit'}
                        onClick={(e) => handleEditUser(e, user.uuid)}
                        sx={{ cursor: 'pointer' }}
                      />
                      <DeleteButton
                        label={'Smazat'}
                        onClick={(e) => handleDeleteUser(e, user.uuid)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {confirmDialogValue && (
            <ConfirmationDialog
              id="confirm-dialog-delete-user"
              keepMounted
              open={openConfirmDialog}
              onClose={handleCloseConfirmDialog}
              value={confirmDialogValue}
              confirmMessage={'Přejete si opravdu smazat tuto položku?'}
            />
          )}
        </div>
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Nebyli nalazeni žádní uživatelé
            </Typography>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
};

export default UserList;
