import axios from 'axios';
import { API_BASE_URL } from './config';

const getConfig = (accessToken) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  };
};

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  // timeout: 50000,
});

export async function login(username, password) {
  return await axiosInstance.post(`/login`, { email: username, password });
}

export async function register(username, password) {
  return await axiosInstance.post(`/registration`, { email: username, password });
}

export async function passwordReset(email) {
  return await axiosInstance.post(`/password-reset`, { email });
}

export async function getRoles(accessToken) {
  return await axiosInstance.get(`/roles`, getConfig(accessToken));
}

export async function getUsers(accessToken) {
  return await axiosInstance.get(`/users`, getConfig(accessToken));
}

export async function getUser(accessToken, userUuid) {
  return await axiosInstance.get(`/users/${userUuid}`, getConfig(accessToken));
}

export async function createUser(accessToken, userData) {
  return await axiosInstance.post(
    `/users`,
    { ...userData, roles: [userData.userRoles] },
    getConfig(accessToken)
  );
}

export async function updateUser(accessToken, userUuid, userData) {
  return await axiosInstance.put(
    `/users/${userUuid}`,
    { ...userData, roles: [userData.userRoles] },
    getConfig(accessToken)
  );
}

export async function deleteUser(accessToken, userUuid) {
  return await axiosInstance.delete(`/users/${userUuid}`, getConfig(accessToken));
}

export async function getLoggedUser(accessToken) {
  return await axiosInstance.get(`/user/account`, getConfig(accessToken));
}

export async function updateLoggedUser(accessToken, password, passwordNew) {
  return await axiosInstance.put(
    `/user/account`,
    {
      password,
      password_new: passwordNew,
      password_new_confirmation: passwordNew,
    },
    getConfig(accessToken)
  );
}

export async function getProjects(accessToken) {
  return await axiosInstance.get(`/projects`, getConfig(accessToken));
}

export async function getCategories(accessToken) {
  return await axiosInstance.get(`/categories`, getConfig(accessToken));
}

export async function getCategory(accessToken, categoryUuid) {
  return await axiosInstance.get(`/categories/${categoryUuid}`, getConfig(accessToken));
}

export async function createCategory(accessToken, categoryData) {
  const { title, slug, description, isActive } = categoryData;

  return await axiosInstance.post(
    `/categories`,
    {
      title,
      slug,
      description,
      is_active: isActive,
    },
    getConfig(accessToken)
  );
}

export async function updateCategory(accessToken, categoryUuid, categoryData) {
  const { title, slug, description, isActive } = categoryData;

  return await axiosInstance.put(
    `/categories/${categoryUuid}`,
    {
      title,
      slug,
      description,
      is_active: isActive,
    },
    getConfig(accessToken)
  );
}

export async function getEnums(accessToken) {
  return await axiosInstance.get(`/enums`, getConfig(accessToken));
}

export async function getOrders(accessToken, perPage = 20, page = 1, filterParams = {}) {
  const params = Object.keys(filterParams).map((key) => `filters[${key}]=${filterParams[key]}`);

  params.push(`sort=created_at`, `per_page=${perPage}`, `page=${page}`);

  return await axiosInstance.get(`/orders?${params.join('&')}`, getConfig(accessToken));
}

export async function getProducts(accessToken, perPage = 20, page = 1, filterParams = {}) {
  const params = Object.keys(filterParams).map((key) =>
    createQueryStringParam(key, filterParams[key])
  );

  params.push(`sort=created_at`, `per_page=${perPage}`, `page=${page}`);

  return await axiosInstance.get(`/products?${params.join('&')}`, getConfig(accessToken));
}

function createQueryStringParam(paramName, paramValue) {
  if (Array.isArray(paramValue)) {
    return paramValue.map((value) => `filters[${paramName}][]=${value}`).join('&');
  }

  return `filters[${paramName}]=${paramValue}`;
}

export async function getProduct(accessToken, productUuid) {
  return await axiosInstance.get(`/products/${productUuid}`, getConfig(accessToken));
}

export async function getProductVariant(accessToken, productUuid, variantUuid) {
  return await axiosInstance.get(
    `/products/${productUuid}/variants/${variantUuid}`,
    getConfig(accessToken)
  );
}

export async function getProductLog(accessToken, productUuid) {
  return await axiosInstance.get(`/products/${productUuid}/log`, getConfig(accessToken));
}

export async function createProduct(accessToken, productData) {
  return await axiosInstance.post(`/products`, productData, getConfig(accessToken));
}

export async function updateProduct(accessToken, productUuid, productData) {
  return await axiosInstance.put(`/products/${productUuid}`, productData, getConfig(accessToken));
}

export async function uploadProductImage(accessToken, productUuid, image) {
  return await axiosInstance.post(`/products/${productUuid}/images`, image, getConfig(accessToken));
}

export async function deleteProduct(accessToken, productUuid) {
  return await axiosInstance.delete(`/products/${productUuid}`, getConfig(accessToken));
}

export async function createProductVariant(accessToken, productUuid, productVariantData) {
  const { purchasePrice, sellingPrice, conditionCategory, stockCount, comment, commentInternal } =
    productVariantData;

  return await axiosInstance.post(
    `/products/${productUuid}/variants`,
    {
      purchase_price: purchasePrice,
      selling_price: sellingPrice,
      condition_category: conditionCategory,
      stock_count: stockCount,
      comment,
      comment_internal: commentInternal,
    },
    getConfig(accessToken)
  );
}

export async function updateProductVariant(
  accessToken,
  productUuid,
  productVariantUuid,
  productVariantData
) {
  const { purchasePrice, sellingPrice, conditionCategory, stockCount, comment, commentInternal } =
    productVariantData;

  return await axiosInstance.put(
    `/products/${productUuid}/variants/${productVariantUuid}`,
    {
      purchase_price: purchasePrice,
      selling_price: sellingPrice,
      condition_category: conditionCategory,
      stock_count: stockCount,
      comment,
      comment_internal: commentInternal,
    },
    getConfig(accessToken)
  );
}

export async function uploadProductVariantImage(
  accessToken,
  productUuid,
  productVariantUuid,
  image
) {
  return await axiosInstance.post(
    `/products/${productUuid}/variants/${productVariantUuid}/images`,
    image,
    getConfig(accessToken)
  );
}

export async function downloadFile(accessToken, fileCode) {
  const config = getConfig(accessToken);

  return await axiosInstance.get(`/files/${fileCode}`, {
    ...config,
    responseType: 'blob',
  });
}
