import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CategoryIcon from '@mui/icons-material/Category';
import ListContentHeader from '../layout/ListContentHeader';
import EditButton from '../utils/buttons/EditButton';
import Loader from '../utils/Loader';
import RichObjectTreeView from '../utils/RichObjectTreeView';
import { getCategories } from '../../api';
import { formatCategoryData, flattenTree } from '../../helpers';
import AddButton from '../utils/buttons/AddButton';

const CategoryList = ({ accessToken }) => {
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryTree, setCategoryTree] = useState([]);
  const [showCategoryTree, setShowCategoryTree] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleCreateCategory = () => {
    navigate(`/categories/create`);
  };

  const handleEditCategory = (event, categoryUuid) => {
    navigate(`/categories/${categoryUuid}/edit`);
  };

  const handleToggleCategoryTree = (event) => {
    setShowCategoryTree(!showCategoryTree);
  };

  const handleUpdateCategoryTree = (event, data) => {
    console.log('handleUpdateCategoryTree', data);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await getCategories(accessToken);

        if (res && res.data.data.categories.length > 0) {
          setCategories(flattenTree(formatCategoryData(res.data.data.categories[0].children)));
          setCategoryTree(res.data.data.categories[0]);
        } else {
          console.error('Error fetching categories');

          setError('Nepodařilo se stáhnout seznam kategorií');
        }
      } catch (err) {
        console.error(err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [accessToken]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <ListContentHeader icon={<CategoryIcon />} title="Kategorie" />
      {categories.length > 0 && (
        <Button onClick={handleToggleCategoryTree}>
          Zobrazit {showCategoryTree ? 'výpis' : 'strom'} kategorií
        </Button>
      )}
      {error && <Alert severity="error">{error}</Alert>}
      <AddButton
        label={'Přidat'}
        variant="contained"
        color="success"
        onClick={handleCreateCategory}
      />
      {categories.length > 0 ? (
        <Box>
          {!showCategoryTree ? (
            <TableContainer component={Paper} sx={{ marginTop: 5 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Název</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Poslední změna</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.map((category) => (
                    <TableRow
                      key={category.uuid}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{category.title}</TableCell>
                      <TableCell>
                        {dayjs(new Date(category.updated_at)).format('DD.MM.YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell>
                        <EditButton
                          label={'Upravit'}
                          onClick={(e) => handleEditCategory(e, category.uuid)}
                          sx={{ cursor: 'pointer' }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <RichObjectTreeView
              data={categoryTree}
              expandedIds={[]}
              onChange={handleUpdateCategoryTree}
            />
          )}
        </Box>
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Nebyly nalezeny žádné kategorie
            </Typography>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
};

export default CategoryList;
