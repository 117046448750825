import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

export default function EditButton({
  label,
  onClick,
  sx,
  variant = 'contained',
  color = 'success',
}) {
  const sxDefault = {
    ...sx,
  };

  return (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      startIcon={<EditIcon />}
      sx={sxDefault}
    >
      {label}
    </Button>
  );
}

EditButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
