import slugify from 'slugify';

export function createSlug(str) {
  if (!str || str.length === 0) {
    return undefined;
  }

  return slugify(str.replace(/[.,_()"':;~!@#$%^&*]/g, ''), {
    lower: true,
  });
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function formatCategoryData(data) {
  return data.map((row) => {
    return {
      ...row,
      id: row.uuid,
      label: row.title,
      children: row.children ? formatCategoryData(row.children) : null,
    };
  });
}

export function flattenTree(treeData) {
  const flattenedArray = [];

  function recursiveFlatten(node) {
    flattenedArray.push(node);

    if (node.children) {
      for (const child of node.children) {
        recursiveFlatten(child);
      }

      delete node.children;
    }
  }

  for (const node of treeData) {
    recursiveFlatten(node);
  }

  return flattenedArray;
}
