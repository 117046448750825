import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

export default function ClearFilterButton({
  label,
  onClick,
  sx,
  variant = 'contained',
  color = 'warning',
}) {
  const sxDefault = {
    margin: 1,
    ...sx,
  };

  return (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      startIcon={<FilterAltOffIcon />}
      sx={sxDefault}
    >
      {label}
    </Button>
  );
}

ClearFilterButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
