import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Loader from '../utils/Loader';
import { getRoles, getUser, createUser, updateUser } from '../../api';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const UserForm = ({ accessToken, isCreate, isAdmin }) => {
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [userRoles, setUserRoles] = useState([]);
  const [userRolesList, setUserRolesList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { uuid } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await getUser(accessToken, uuid);

        if (res) {
          setName(res.data.name);
          setSurname(res.data.surname);
          setEmail(res.data.email);
          setPhone(res.data.phone);
          setUserRoles(res.data.roles);
        } else {
          console.error('Error fetching user data');

          setError('Nepodařilo se získat data o uživateli');
        }
      } catch (err) {
        console.error('Fetch user error', err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isCreate && uuid) {
      fetchUser();
    }
  }, [accessToken, uuid]);

  useEffect(() => {
    const fetchUserRolesList = async () => {
      try {
        const res = await getRoles(accessToken);

        if (res) {
          setUserRolesList(res.data.data);
        } else {
          console.error('Error fetching user roles list');

          setError('Nepodařilo se získat seznam rolí');
        }
      } catch (err) {
        console.error('Fetch user roles list error', err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserRolesList();
  }, [accessToken]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const userData = {
        name,
        surname,
        email,
        phone,
        userRoles,
      };

      if (isCreate) {
        await createUser(accessToken, userData);
      } else {
        await updateUser(accessToken, uuid, userData);
      }
    } catch (err) {
      console.error(err);

      setIsLoading(false);
      setError(err.response.message);
    } finally {
      navigate('/users');

      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {isCreate ? 'Nový uživatel' : 'Úprava uživatele'}
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="name"
            label="Jméno"
            id="name"
            value={name}
            autoComplete="name"
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="surname"
            label="Příjmení"
            id="surname"
            value={surname}
            autoComplete="surname"
            onChange={(event) => setSurname(event.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="email"
            label="E-mail"
            type="email"
            id="email"
            value={email}
            autoComplete="phone"
            onChange={(event) => setEmail(event.target.value)}
            disabled={!isCreate}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="phone"
            label="Telefon"
            id="phone"
            value={phone}
            autoComplete="phone"
            onChange={(event) => setPhone(event.target.value)}
          />
          {isAdmin && (
            <FormControl fullWidth>
              <InputLabel id="user-roles-label">Role</InputLabel>
              <Select
                labelId="user-roles-label"
                id="user-roles"
                value={userRoles}
                label="Role"
                onChange={(event) => setUserRoles(event.target.value)}
                required
              >
                {userRolesList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Uložit
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UserForm;
